const appConfig  = {
    websocketsKey: "naech2eivaiT",
    websocketsServer: "ws.store.sellerassistant.deals",
    googleId: "261251968652-1aa91g4ug6rrvr0u11gv755fm4e3aodd.apps.googleusercontent.com",
    facebookId: "",
    broadcastingAuthUrl: "https://api.store.sellerassistant.deals/api/broadcasting/auth",
    stripePubKey: "pk_live_51LSe1SJBdxyzbfAe1dbX49KPNmnfqqO5I43oZUIFjop3Ax9EO2DrtSHRgl2qGRn8GM5iRHiMcvfRanzE868ozycO00a3n6oKTA",
    stripeAcc: "deals",
    gTag: "GTM-5QRTQ2M",
    intercomId: "r9id9lv6",
    amplitudeApiKey: "281a1902fc42b60e3e662c6157cbbb84",
    mixPanelToken: "14fe3b6ad7e807719f08d86910188bad",
    backEndUrl: "https://api.store.sellerassistant.deals/api"
}

export default appConfig
